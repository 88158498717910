export const faqData = [
    {
      question: "What services does WODO offer?",
      answer:
        "WODO specializes in Digital Marketing, Web Design & Development Services, including custom web design, website development, SEO, PPC, social media management, and more.",
    },
    {
      question: "Why is custom web design important for my business?",
      answer:
        "Custom web design ensures your website is unique, aligns with your brand, and enhances the user experience, which can lead to higher engagement and conversions.",
    },
    {
      question: "How does WODO approach digital marketing?",
      answer:
        "We use data-driven strategies tailored to your business goals, focusing on SEO, PPC, content marketing, and social media to drive traffic and increase conversions.",
    },
    {
      question: "What makes WODO different from other agencies?",
      answer:
        "WODO combines creative design with technical expertise, offering personalized solutions that are innovative, scalable, and aligned with your business needs.",
    },
    {
      question: "How can WODO help improve my website's performance?",
      answer:
        "We enhance your website's performance by optimizing design, improving load times, and implementing effective SEO strategies to boost your online visibility and user engagement.",
    },
    {
      question: "What industries does WODO serve?",
      answer:
        "WODO provides services to a wide range of industries, including e-commerce, healthcare, finance, technology, and more, tailoring our approach to meet specific industry needs.",
    },
    {
      question: "How can digital marketing services boost my business?",
      answer:
        "Digital marketing services, such as SEO and PPC, increase your online visibility, attract more targeted traffic, and ultimately lead to higher sales and business growth.",
    },
    {
      question: "What is the process for working with WODO?",
      answer:
        "Our process involves understanding your goals, creating a customized plan, executing the strategy, and providing ongoing support and optimization to ensure success.",
    },
  ];
  