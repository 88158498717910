import React, { useState, useRef, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./contact.css";
import Form from "./Form";
import { gsap } from "gsap";
import ContactForm from "./contactForm";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function Contact() {
  //   const canonicalUrl = "https://wodo.digital/contact-us/";
  const formRef = useRef(null);
  const tl = useRef(null);
  const [MenuStatus, setMenuStatus] = useState(false);
  const handleButtonClick = () => {
    setMenuStatus(!MenuStatus);
  };
  useEffect(() => {
    tl.current = gsap.timeline({ paused: true });
    tl.current.to(formRef.current, {
      top: 0,
      duration: 0.7,
      ease: "power.out",
    });
  }, []);

  useEffect(() => {
    if (MenuStatus) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
  }, [MenuStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Contact WODO | Your Digital Marketing & Creative Partner
        </title>
        <meta
          name="description"
          content="Get in touch with WODO, your go-to digital marketing and creative agency. Whether you need help with web development, branding, or marketing strategy, we’re here to assist. Contact us today!"
        />
        <meta
          name="keywords"
          content="Contact WODO, digital marketing contact, creative agency contact, web development inquiry, branding consultation, WODO support​"
        />
        <meta
          property="og:title"
          content="Contact WODO | Let’s Create Something Great Together"
        ></meta>

        <meta
          property="og:description"
          content="Reach out to WODO for all your digital marketing and creative needs. We’re excited to partner with you to transform your brand and drive growth. Get in touch with us today!"
        ></meta>
        <meta
          property="og:image"
          content="https://wodo.digital/media/home/featured-image.webp"
        />
        <meta
          property="og:image:alt"
          content="Digital Marketing, Web Design & Development Services at WODO"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />
        <meta property="og:url" content="https://wodo.digital/contact-us/" />
        <link rel="canonical" href="https://wodo.digital/contact-us/" />

        <meta name="publisher" content="WODO" />
        <meta name="author" content="WODO" />
        <meta name="copyright" content="WODO" />
        <link
          rel="publisher"
          href="https://in.linkedin.com/company/wodo-digital"
        />

        {/* Organization Schema */}
        <script type="application/ld+json">
          {`
            { 

            "@context": "https://schema.org", 

            "@type": "Organization", 

            "name": "Wodo", 

            "url": "https://wodo.digital", 

            "logo": "https://wodo.digital/static/media/wodo-logo.1e15894e3c85bd916a05.png", 

            "contactPoint": { 

                "@type": "ContactPoint", 

                "email": "hello.wododigital@gmail.com", 

                "telephone": "+919482290970", 

                "contactType": "Customer Service" 

            }, 

            "address": { 

                "@type": "PostalAddress", 

                "streetAddress": "1st floor, Laksmi Arcade, 1, 1st Main Rd, BDA Layout, Marilingappa Extension, Annapurneshwari Nagar", 

                "addressLocality": "Bengaluru", 

                "addressRegion": "Karnataka", 

                "postalCode": "560091", 

                "addressCountry": "IN" 

            }, 

            "sameAs": [ 

                "https://in.linkedin.com/company/wodo-digital", 

                "https://www.instagram.com/wodo.digital/" 

            ] 

            } 
        
        
        `}
        </script>

        {/* Local Schema */}
        <script type="application/ld+json">
          {`
             { 

                "@context": "https://schema.org", 

                "@type": "LocalBusiness", 

                "name": "Wodo", 

                "image": "https://wodo.digital/static/media/wodo-logo.1e15894e3c85bd916a05.png", 

                "address": { 

                    "@type": "PostalAddress", 

                    "streetAddress": "1st floor, Laksmi Arcade, 1, 1st Main Rd, BDA Layout, Marilingappa Extension, Annapurneshwari Nagar", 

                    "addressLocality": "Bengaluru", 

                    "addressRegion": "Karnataka", 

                    "postalCode": "560091", 

                    "addressCountry": "IN" 

                }, 

                "telephone": "+919482290970", 

                "email": "hello.wododigital@gmail.com", 

                "url": "https://wodo.digital", 

                "openingHours": [ 

                    "Mo-Su 08:00-22:00" 

                ], 

                "sameAs": [ 

                    "https://in.linkedin.com/company/wodo-digital", 

                    "https://www.instagram.com/wodo.digital/" 

                ] 

                } 
        
        `}
        </script>
      </Helmet>
      <Form
        ContainerRef={formRef}
        formSlider={MenuStatus}
        NavMenuHandle={handleButtonClick}
      />
      <Header />
      <div className="contact">
        <div className="container py-md-5 py-2">
          <div className="row">
            <div className="col-md-6">
              <h2 className="gf_h5 storysection-h5">Contact Us</h2>
              <h1 className="c_h1 pb-2">
                Tell us your wildest idea, and let's build it together!
              </h1>
              <Link to="tel:+919482290970">
                <div className="address-box">
                  <h3 className="gf_h4">
                    <span className="yellow-text">India</span> Office
                  </h3>
                  <p className="gf_h5">Anuj Srivastava</p>
                  <p className="gf_text">+91 80889 98516</p>
                </div>
              </Link>
              <Link to="tel:+1 (980) 335-7724">
                <div className="address-box">
                  <h3 className="gf_h4">
                    <span className="yellow-text">USA</span> Office
                  </h3>
                  <p className="gf_h5">Rahul Sajjan</p>
                  <p className="gf_text">+1 (980) 335-7724</p>
                </div>
              </Link>
              <Link to="tel:+971%2050%20722%208748">
                <div className="address-box">
                  <h3 className="gf_h4">
                    <span className="yellow-text">UAE</span> Office
                  </h3>
                  <p className="gf_h5">Suhas Ashok</p>
                  <p className="gf_text">+971 50 722 8748</p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 py-md-0 py-2">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
